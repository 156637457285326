/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
//import mobile from '../../images/loginicon/mobile.svg';
import ShowAlert from '../../components/ShowAlert';
import { verifyOTP } from '../../api/authenticationAPI';
import whatsappimg from '../../images/whatsappimg.png';
import whatsappicon from '../../images/whatsappicon.png';
import mailicon from '../../images/mailicon.png';
import './LoginScreen.scss';
import './Verify.css';

const Verify = (props) => {
  const {
    mobile_no, otp_verification_id, handleProceed, resendOTP, signUpError, showEmailOption = false, emailAddress
  } = props;
  const [otp, setOTP] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const [timer, setTimer] = useState(120);
  const [newTimer, setNewTimer] = useState("02:00");
  const [newOtp, setNewOtp] = useState([-1, -1, -1, -1, -1, -1])

  const [showEmailOptionPopup, setShowEmailOptionPopup] = useState(false);
  const [showEmailOTPVerifyPopup, setShowEmailOTPVerifyPopup] = useState(false);

  const intervalRef = useRef(null);

  const otpVerification = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      country_code: '+91',
      mobile_no: parseInt(mobile_no),
      otp_verification_id,
      otp: parseInt(otp),
    };
    try {
      const result = await verifyOTP(data);
      handleProceed(result.data.otp_verification_id);
    } catch (err) {
      console.error('Error in login: ', err);
      // if (err.code === 412 && setIsOTPSent) {
      //   setIsOTPSent(false)
      // }
      setError(err.message);
      if (signUpError) signUpError(err.message)
    }
    setLoading(false);

  };

  const str_pad_left = (string, pad, length) => {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }

  const startTimer = () => {

    clearInterval(intervalRef.current); // Clear any existing interval

    setTimer(120);
    let newTimer = 120;

    intervalRef.current = setInterval(() => {
      if (newTimer > 0) {
        newTimer -= 1;

        const minutes = Math.floor(newTimer / 60);
        const seconds = newTimer % 60;

        const finalTime = str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);

        setNewTimer(finalTime);
        setTimer(newTimer);
      
      } else {
        clearInterval(intervalRef.current); // Stop the timer when it reaches 0
      }
    }, 1000);

  };

  const setEmailOTP = async (e) => {
    try {
      setLoading(true);
      await resendOTP(e, true);
    } catch (err) {
      setError(err?.message);
    } finally {
      setLoading(false);
    }
  }

  const otpResent = async (e, isEmailOtp = false) => {
    clearInterval(intervalRef.current); // Stop the timer when OTP is resent
    try{  
      setNewTimer("02:00");    
      setLoading(true);
      await resendOTP(e, isEmailOtp);
    }catch(err) {
      console.log(err?.message)
    }finally{
      setTimer(0);
      setLoading(false);
      startTimer();
    }    
  };

  useEffect(() => {
    startTimer();
    
    return () => clearInterval(intervalRef.current); // Cleanup on component unmount

    //eslint-disable-next-line
  }, []);

  const verifyOtpLength = (newOtp) => {
    const status = newOtp.every((item) => {
      return parseInt(item) > -1
    })
    return status
  }
  const handleOtpChange = (e, index) => {
    const target = e.target;
    const val = target.value;
    newOtp[index] = val ? parseInt(val) : -1;
    if (verifyOtpLength(newOtp)) {
      const OtpString = newOtp.join("");
      setOTP(parseInt(OtpString))
    } else {
      setOTP('')
    }
    setNewOtp((prev) => {
      prev = newOtp
      return [...prev]
    })
  }

  useEffect(() => {
    const inputs = document.getElementById("verifyacwhatsapp_inputs");
    if (inputs) {
      inputs.addEventListener("input", function (e) {
        const target = e.target;
        const val = target.value;

        if (isNaN(val)) {
          target.value = "";
          return;
        }

        if (val !== "") {
          const next = target.nextElementSibling;
          if (next) {
            next.focus();
          }
        }
      });

      inputs.addEventListener("keyup", function (e) {
        const target = e.target;
        const key = e.key.toLowerCase();
        if (key === "backspace" || key === "delete") {
          target.value = "";
          const prev = target.previousElementSibling;
          if (prev) {
            prev.focus();
          }
          return;
        }
      });
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const inputs = document.getElementById("verifyacwhatsapp_inputsEmail");
    if (inputs) {
      inputs.addEventListener("input", function (e) {
        const target = e.target;
        const val = target.value;

        if (isNaN(val)) {
          target.value = "";
          return;
        }

        if (val !== "") {
          const next = target.nextElementSibling;
          if (next) {
            next.focus();
          }
        }
      });

      inputs.addEventListener("keyup", function (e) {
        const target = e.target;
        const key = e.key.toLowerCase();
        if (key === "backspace" || key === "delete") {
          target.value = "";
          const prev = target.previousElementSibling;
          if (prev) {
            prev.focus();
          }
          return;
        }
      });
    }


    //eslint-disable-next-line
  }, [showEmailOTPVerifyPopup]);

  if (showEmailOTPVerifyPopup) {

    return (
      <>
        <div>
          <ShowAlert
            error={error}
            success={success}
            onClose={() => { setError(null); setSuccess(null); }}
          />
          <div className="container clear-padd ma-0 position-center ng-scope bgclretds mt-5">
            <div className="col-xs-2 col-sm-2 col-sm-2 login-empty-container" />
            <div className="col-xs-8 col-lg-8 col-md-12 col-sm-12 login-container mt-28 vertical-center">
              <div className="row content content-sm justify-content-center">
                <div>
                  <div className="col-sm-12 col-xs-12 col-sm-12 col-md-12 removePadding formDiv">

                    <form className="form-horizontal logforms" onSubmit={(e) => otpVerification(e)} autoComplete="off">
                      <div className="Verify_your_account_whatsapp_modal" style={{ marginTop: '1rem' }}>
                        <div class="row">
                          <div class="col-md-12 text-center">
                            <h4 class="Verify_your_account_whatsapp_notextfs">
                              Verification of account through registered email  ID
                            </h4>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-4 text-center">
                            <div class="Verifyyour_account_whatsapp_leftboximg">
                              <img alt='' src={mailicon} width="150" />
                            </div>
                          </div>
                          <div class="col-md-8 text-center">
                            <div class="Verifyyour_account_whatsapp_rightbox pt-2">
                              <p class="Verifyyour_account_whatsapp_textfs">
                                6-digit code (Valid for 2 Mins) sent on {emailAddress}                               
                              </p>

                              <div id="verifyacwhatsapp_inputsEmail" className="verifyacwhatsapp_inputsEmail">
                                <input autoFocus className="verifyacwhatsapp_inputsbox" onChange={(e) => handleOtpChange(e, 0)} type="text" inputmode="numeric" maxlength="1" />
                                <input className="verifyacwhatsapp_inputsbox" onChange={(e) => handleOtpChange(e, 1)} type="text" inputmode="numeric" maxlength="1" />
                                <input className="verifyacwhatsapp_inputsbox" onChange={(e) => handleOtpChange(e, 2)} type="text" inputmode="numeric" maxlength="1" />
                                <input className="verifyacwhatsapp_inputsbox" onChange={(e) => handleOtpChange(e, 3)} type="text" inputmode="numeric" maxlength="1" />
                                <input className="verifyacwhatsapp_inputsbox" onChange={(e) => handleOtpChange(e, 4)} type="text" inputmode="numeric" maxlength="1" />
                                <input className="verifyacwhatsapp_inputsbox" onChange={(e) => handleOtpChange(e, 5)} type="text" inputmode="numeric" maxlength="1" />
                              </div>

                              <div className="verifyacwhatsapp_resendotp_box">
                                {
                                  timer > 0
                                    ?
                                    <>
                                      <Button
                                        type="submit"
                                        className="verifyacwhatsapp_resendotp_btnbgclr"
                                        name="VerifyOTP"
                                        disabled={loading || !otp}
                                      >
                                        {loading ? (
                                          <>
                                            <Spinner
                                              as="span"
                                              animation="border"
                                              size="sm"
                                              role="status"
                                              aria-hidden="true"
                                            />
                                            {' '}
                                            Loading...
                                          </>
                                        ) : 'Verify OTP'}
                                      </Button>
                                      <span className="verifyacwhatsapp_resendotp_timetextfs">
                                        ({newTimer})
                                      </span>
                                    </>
                                    :
                                    <>
                                      <Button className="verifyacwhatsapp_resendotp_btnbgclr" variant="link" onClick={(e) => otpResent(e, true)} disabled={(timer > 0)}>Resend OTP</Button>
                                    </>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-2 login-empty-container" />
          </div >
        </div >
      </>
    )

  } else if (showEmailOptionPopup) {

    return (
      <>
        <div>
          <div className="container clear-padd ma-0 position-center ng-scope bgclretds mt-5">
            <div className="col-xs-2 col-sm-2 col-sm-2 login-empty-container" />
            <div className="col-xs-8 col-lg-8 col-md-12 col-sm-12 login-container mt-28 vertical-center">
              <div className="row content content-sm justify-content-center">
                <div>
                  <div className="col-sm-12 col-xs-12 col-sm-12 col-md-12 removePadding formDiv">
                    <div className="Verify_your_account_whatsapp_modal" style={{ marginTop: '1rem', marginBottom: '1rem' }}>

                      <div className="row">
                        <div className="col-md-12 text-center">
                          <h4 className="Verify_your_account_whatsapp_notextfs">
                            Verify your account through registered email
                          </h4>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12 text-center">
                          <div className="Verifyyour_account_whatsapp_rightbox pt-2">
                            <p className="Verifyyour_account_whatsapp_textfs">
                              You will receive 6-digit code on {emailAddress}
                            </p>
                            <div className="verifyacwhatsapp_resendotp_box pt-1" data-toggle="modal" data-target="#Verify_your_account_whatsapp_popup04">
                              <button className="verifyacwhatsapp_resendotp_btnbgclr" onClick={(e) => {
                                setShowEmailOTPVerifyPopup(true)
                                setEmailOTP(e)
                              }
                              }>
                                Resend OTP
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    )
  } else {

    return (
      <div>
        <ShowAlert
          error={error}
          success={success}
          onClose={() => { setError(null); setSuccess(null); }}
        />
        <div className="container clear-padd ma-0 position-center ng-scope bgclretds mt-5">
          <div className="col-xs-2 col-sm-2 col-sm-2 login-empty-container" />
          <div className="col-xs-8 col-lg-8 col-md-12 col-sm-12 login-container mt-28 vertical-center">
            <div className="row content content-sm justify-content-center">
              <div>
                <div className="col-sm-12 col-xs-12 col-sm-12 col-md-12 removePadding formDiv">

                  <form className="form-horizontal logforms" onSubmit={(e) => otpVerification(e)} autoComplete="off">
                    <div className="Verify_your_account_whatsapp_modal" style={{ marginTop: '1rem' }}>
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <h4 className="Verify_your_account_whatsapp_notextfs">
                            Verify your account through Whatsapp  no.
                          </h4>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 text-center">
                          <div className="Verifyyour_account_whatsapp_leftboximg">
                            <img alt='' src={whatsappimg} width="150" />
                          </div>
                        </div>
                        <div className="col-md-8 text-center">
                          <div className="Verifyyour_account_whatsapp_rightbox">
                            <p className="Verifyyour_account_whatsapp_textfs">
                              6-digit code (Valid for 2 Mins) sent on {mobile_no}
                              <span className="Verifyyour_account_whatsappiconbox">
                                <img alt='' src={whatsappicon} width="18" />
                              </span>
                            </p>
                            <div id="verifyacwhatsapp_inputs" className="verifyacwhatsapp_inputs">
                              <input autoFocus className="verifyacwhatsapp_inputsbox" onChange={(e) => handleOtpChange(e, 0)} type="text" inputmode="numeric" maxlength="1" />
                              <input className="verifyacwhatsapp_inputsbox" onChange={(e) => handleOtpChange(e, 1)} type="text" inputmode="numeric" maxlength="1" />
                              <input className="verifyacwhatsapp_inputsbox" onChange={(e) => handleOtpChange(e, 2)} type="text" inputmode="numeric" maxlength="1" />
                              <input className="verifyacwhatsapp_inputsbox" onChange={(e) => handleOtpChange(e, 3)} type="text" inputmode="numeric" maxlength="1" />
                              <input className="verifyacwhatsapp_inputsbox" onChange={(e) => handleOtpChange(e, 4)} type="text" inputmode="numeric" maxlength="1" />
                              <input className="verifyacwhatsapp_inputsbox" onChange={(e) => handleOtpChange(e, 5)} type="text" inputmode="numeric" maxlength="1" />
                            </div>
                            <div className="verifyacwhatsapp_resendotp_box">
                              {
                                timer > 0
                                  ?
                                  <>
                                    <Button
                                      type="submit"
                                      className="verifyacwhatsapp_resendotp_btnbgclr"
                                      name="VerifyOTP"
                                      disabled={loading || !otp}
                                    >
                                      {loading ? (
                                        <>
                                          <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                          {' '}
                                          Loading...
                                        </>
                                      ) : 'Verify OTP'}
                                    </Button>
                                    <span className="verifyacwhatsapp_resendotp_timetextfs">
                                      ({newTimer})
                                    </span>
                                  </>
                                  :
                                  <>
                                    <Button className="verifyacwhatsapp_resendotp_btnbgclr" variant="link" onClick={(e) => otpResent(e)} disabled={(timer > 0)}>Resend OTP</Button>
                                  </>
                              }

                              {
                                showEmailOption === true &&
                                <div className="verifyacwhatsapp_resendotptextbox">
                                  <span className="verifyacwhatsapp_resendotp_donttextfs" onClick={() => setShowEmailOptionPopup(true)} >
                                    I don't have access to WhatsApp
                                  </span>
                                </div>
                              }

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-2 login-empty-container" />
        </div>
      </div>
    );

  }

};

export default Verify;
