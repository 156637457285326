/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
import React, { Suspense, lazy } from 'react';
import './App.scss';
import { Route, Switch, Redirect } from 'react-router-dom';
import { BASE_PATH, IS_MAITENANCE_MODE_ENABLED } from './app/constants';
import PrivateRoute from './routes/PrivateRoute';
import ScrollToTop from './components/ScrollToTop';
import AuthenticationRoute from './routes/AuthenticationRoute';

import LoadingIndicator from './components/loadingIndicator/LoadingIndicator';
import LoginScreen from './features/authentication/LoginScreen';
import SignUpScreen from './features/authentication/SignUpScreen';
import ForgetPass from './features/authentication/ForgetPass';
import ResetPass from './features/authentication/ResetPass';
import NotFound from './components/NotFound';
import Maintenance from './Maintenance';
import Header from "./features/header/Header";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@progress/kendo-theme-default/dist/all.css';

export const LOGIN_PATHS = [
  `${BASE_PATH}login`,
  `${BASE_PATH}signup`,
  `${BASE_PATH}forgetpass`,
  `${BASE_PATH}resetpass`,
  `${BASE_PATH}maintenance`,
];

export const AY_DROPDOWN_PATHS = [
  `${BASE_PATH}dashboard`,
];

export const EXCLUDE_COMPARE_REGIME_PATHS = [
  `${BASE_PATH}efiling`,
  `${BASE_PATH}efiling-review`,
  `${BASE_PATH}residential-status`,
  `${BASE_PATH}share-holder-details`,
  `${BASE_PATH}schedule-fa`,
  `${BASE_PATH}schedule-al`,
  `${BASE_PATH}bp/business-information/basic-info`,
  `${BASE_PATH}bp/business-information/gst-details`,
  `${BASE_PATH}bp/business-information/holding-status`,
  `${BASE_PATH}bp/business-information/key-persons`,
  `${BASE_PATH}bp/business-information/member-details`,
  `${BASE_PATH}bp/business-information/org-info`,
  `${BASE_PATH}bp/business-information/schedule-qd`,
  `${BASE_PATH}bp/trial-balance`,
  `${BASE_PATH}bp/form-3ca-3cd`,
  `${BASE_PATH}bp/schedule3`,
  `${BASE_PATH}bp/financial-statements`,
  `${BASE_PATH}bp/financial-statements-preview`,
  `${BASE_PATH}bp/audit-information`,
  `${BASE_PATH}bp/itr-balance-sheet`,
  `${BASE_PATH}efiling-review-audit`
]


export const MISC_PATHS = [
  `${BASE_PATH}profile`,
  `${BASE_PATH}plans`,
  `${BASE_PATH}subscription`,
  `${BASE_PATH}user-management`,
  `${BASE_PATH}user-profile`,
  `${BASE_PATH}user-management/manage-clients`,
  `${BASE_PATH}user-management/client-user-map`,  
]

export const REGIME_TOGGLE_PATHS = [
  `${BASE_PATH}efiling`,
  `${BASE_PATH}efiling-review`,
  `${BASE_PATH}computation`,
];

const ClientList = lazy(() => import('./features/ListofClient/ClientList'));
const AddClient = lazy(() => import('./features/client/AddNewClient'));
const AddClientDetail = lazy(() => import('./features/client/AddClientDetail'));
const ClientAlldata = lazy(() => import('./features/ListofClient/ClientAllData'))
const PendingClientList = lazy(() => import('./features/ListofClient/PendingClientList'))

// Profile and User Management
const Profile = lazy(() => import('./features/profile/Profile'));
const Subscription = lazy(() => import('./features/profile/Subscription'));
const Plans = lazy(() => import('./features/Plans/Plans'));
const UserList = lazy(() => import('./features/userManagement/UserList'));
const UserProfile = lazy(() => import('./features/userManagement/UserProfile'));
const ManageClients = lazy(() => import('./features/userManagement/ManageClients'));
const ClientUserMapListing = lazy(() => import('./features/userManagement/ClientUserMapListing'));

const SalaryIncome = lazy(() => import('./features/income/salaryIncome/SalaryIncome'));
const HouseProperty = lazy(() => import('./features/income/houseProperty/HouseProperty'));
const Share = lazy(() => import('./features/income/capitalGain/shares/Share'))
const EquitylinkMutualfund = lazy(() => import('./features/income/capitalGain/EquityLinkMutualFunds/EquitylinkMutualfund'))
const BuildingLand = lazy(() => import('./features/income/capitalGain/buildingLand/BuildingLand'));
const SlumpSale = lazy(() => import('./features/income/capitalGain/slumpSale/SlumpSale'));
const BondDebentures = lazy(() => import('./features/income/capitalGain/BondsDebenture/BondsDebenture'))
const FII = lazy(() => import('./features/income/capitalGain/FII/Fii'))
const Others = lazy(() => import('./features/income/capitalGain/Others/Others'))
const DeemedCG = lazy(() => import('./features/income/capitalGain/DeemedCG/DeemedCG'))
const OtherSource = lazy(() => import('./features/income/otherSource/OtherSource'));

const BpBasicInfo = lazy(() => import('./features/income/businessProfession/businessInfo/basicInfo/BasicInfo'));
const BpGstDetails = lazy(() => import('./features/income/businessProfession/businessInfo/gstDetails/GstDetails'));
const BpHoldingStatus = lazy(() => import('./features/income/businessProfession/businessInfo/holdingStatus/HoldingStatus'));
const BpKeyPersons = lazy(() => import('./features/income/businessProfession/businessInfo/keyPersons/KeyPersonOwnershipInfo'));
const BpMemberDetails = lazy(() => import('./features/income/businessProfession/businessInfo/memberDetails/MemberDetails'));
const BpOrgInfo = lazy(() => import('./features/income/businessProfession/businessInfo/orgInfo/OrgInfo'));
const BpSchQD = lazy(() => import('./features/income/businessProfession/businessInfo/scheduleQD/ScheduleQD'));

const BpTrialBalance = lazy(() => import('./features/income/businessProfession/trialBalance/TrialBalance'));
const ConsolidatedSchBp = lazy(() => import('./features/income/businessProfession/consolidatedScheduleBp/ConsolidatedScheduleBp'));
const BpForm3CA3CD = lazy(() => import('./features/income/businessProfession/form3CA3CD/Form3CA3CD'));
const BpSchedule3 = lazy(() => import('./features/income/businessProfession/schedule3/Schedule3'));
const FinancialStatements = lazy(() => import('./features/income/businessProfession/financialStatements/FinancialStatements'));
const FinancialStatementsPreview = lazy(() => import('./features/income/businessProfession/financialStatements/financialStatementsPreview/FinancialStatementsPreview'));
const ItrBalanceSheet = lazy(() => import('./features/income/businessProfession/itrBalanceSheet/ItrBalanceSheet'));

const ClubbingModule = lazy(() => import('./features/income/clubbing/ClubbingModule'))
const ExemptIncome = lazy(() => import('./features/income/exemptIncome/ExemptIncome'))
const Deduction = lazy(() => import('./features/income/deduction/Deduction'))
const Shedule10AARA = lazy(() => import('./features/income/deduction/Shedule10AARA'))
const SchedulePti = lazy(() => import('./features/income/schedulePti/SchedulePti'))

const ScheduleMAT = lazy(() => import('./features/income/MATandAMT/scheduleMAT/ScheduleMAT'));
const ScheduleAMT = lazy(() => import('./features/income/MATandAMT/scheduleAMT/ScheduleAMT'));

const ScheduleAL = lazy(() => import('./features/income/scheduleAL/ScheduleAL'));
const ScheduleFA = lazy(() => import('./features/income/scheduleFA/ScheduleFA'));
const Losses = lazy(() => import('./features/income/losses/Losses'));
const TaxDetails = lazy(() => import('./features/income/taxDetails/TaxDetails'));
const Computation = lazy(() => import('./features/income/computation/Computation'));
const Dashboard = lazy(() => import('./features/dashboard/Dashboard'));

const AuditedForms = lazy(() => import('./features/income/auditedForms/AuditedForms'));

const CAMaster = lazy(() => import('./features/CAMaster/CAMaster'));
const SaveCAMaster = lazy(() => import('./features/CAMaster/AddCAMaster'));
const ReturnRegisterAyWise = lazy(() => import('./features/returnRegister/asWise/ReturnRegisterAyWise'));
const ReturnRegisterClientWise = lazy(() => import('./features/returnRegister/clientWise/ReturnRegisterClientWise'));

const Efiling = lazy(() => import('./features/income/eFiling/Efiling'));
const EfilingReview = lazy(() => import('./features/income/eFiling/EfilingReview'));
const ShareHolderDetails = lazy(() => import('./features/income/shareHolderDetails/ShareHolderDetails'));
const ResidentialStatus = lazy(() => import('./features/income/residentialStatus/ResidentialStatus'));

const SchduleVDA = lazy(() => import('./features/income/scheduleVDA/ScheduleVDA'));
const ShareHolding = lazy(() => import('./features/income/SchShareHolding/SchShareHolding'));

const EfilingReviewAudit = lazy(() => import('./features/income/eFilingAudit/EfilingReviewAudit'));

const TwoFactorAuthentication = lazy(() => import('./features/authentication/TwoFactorAuthentication'));

function App() {

  if (IS_MAITENANCE_MODE_ENABLED) {
    return (
      <Switch>
        <Route path={`${BASE_PATH}maintenance`} component={Maintenance} />
        <Redirect to={`${BASE_PATH}maintenance`} />
      </Switch>
    );
  }



  return (
    <main>
      <ScrollToTop />
      <Header />
      <Suspense fallback={<LoadingIndicator />}>
        <Switch>
          <PrivateRoute path={`${BASE_PATH}profile`} component={Profile} />
          <PrivateRoute path={`${BASE_PATH}subscription`} component={Subscription} exact />
          <PrivateRoute path={`${BASE_PATH}plans`} component={Plans} exact />
          <PrivateRoute path={`${BASE_PATH}user-management`} component={UserList} exact />
          <PrivateRoute path={`${BASE_PATH}user-profile`} component={UserProfile} />
          <PrivateRoute path={`${BASE_PATH}user-management/manage-clients`} component={ManageClients} exact />
          <PrivateRoute path={`${BASE_PATH}user-management/client-user-map`} component={ClientUserMapListing} exact /> 

          <PrivateRoute path={`${BASE_PATH}client/recent`} component={ClientList} exact />
          <PrivateRoute path={`${BASE_PATH}client/all`} component={ClientAlldata} />
          <PrivateRoute path={`${BASE_PATH}client/add`} component={AddClient} exact />
          <PrivateRoute path={`${BASE_PATH}client/adddetail`} component={AddClientDetail} exact />
          <PrivateRoute path={`${BASE_PATH}client/pending`} component={PendingClientList} exact />

          <PrivateRoute path={`${BASE_PATH}dashboard`} component={Dashboard} />

          <PrivateRoute path={`${BASE_PATH}camaster/details`} component={SaveCAMaster} exact />
          <PrivateRoute path={`${BASE_PATH}camaster`} component={CAMaster} exact />


          <PrivateRoute path={`${BASE_PATH}salary`} component={SalaryIncome} />

          <PrivateRoute path={`${BASE_PATH}houseproperty`} component={HouseProperty} />

          <PrivateRoute path={`${BASE_PATH}capitalgain/shares`} component={Share} />
          <PrivateRoute path={`${BASE_PATH}capitalgain/buildingland`} component={BuildingLand} />
          <PrivateRoute path={`${BASE_PATH}capitalgain/slumpsale`} component={SlumpSale} />
          <PrivateRoute path={`${BASE_PATH}capitalgain/equitylinkmf`} component={EquitylinkMutualfund} />
          <PrivateRoute path={`${BASE_PATH}capitalgain/bonddebenture`} component={BondDebentures} />
          <PrivateRoute path={`${BASE_PATH}capitalgain/fii`} component={FII} />
          <PrivateRoute path={`${BASE_PATH}capitalgain/others`} component={Others} />
          <PrivateRoute path={`${BASE_PATH}capitalgain/deemedcg`} component={DeemedCG} />

          <PrivateRoute path={`${BASE_PATH}othersource`} component={OtherSource} />

          <PrivateRoute path={`${BASE_PATH}bp/business-information/basic-info`} component={BpBasicInfo} />
          <PrivateRoute path={`${BASE_PATH}bp/business-information/gst-details`} component={BpGstDetails} />
          <PrivateRoute path={`${BASE_PATH}bp/business-information/holding-status`} component={BpHoldingStatus} />
          <PrivateRoute path={`${BASE_PATH}bp/business-information/key-persons`} component={BpKeyPersons} />
          <PrivateRoute path={`${BASE_PATH}bp/business-information/member-details`} component={BpMemberDetails} />
          <PrivateRoute path={`${BASE_PATH}bp/business-information/org-info`} component={BpOrgInfo} />
          <PrivateRoute path={`${BASE_PATH}bp/business-information/schedule-qd`} component={BpSchQD} />

          <PrivateRoute path={`${BASE_PATH}bp/trial-balance`} component={BpTrialBalance} />
          <PrivateRoute path={`${BASE_PATH}bp/consolidated-schedule-bp`} component={ConsolidatedSchBp} />
          <PrivateRoute path={`${BASE_PATH}bp/form-3ca-3cd`} component={BpForm3CA3CD} />
          <PrivateRoute path={`${BASE_PATH}bp/schedule3`} component={BpSchedule3} />
          <PrivateRoute path={`${BASE_PATH}bp/financial-statements`} component={FinancialStatements} />
          <PrivateRoute path={`${BASE_PATH}bp/financial-statements-preview`} component={FinancialStatementsPreview} />
          <PrivateRoute path={`${BASE_PATH}bp/audit-information`} component={AuditedForms} />
          <PrivateRoute path={`${BASE_PATH}bp/itr-balance-sheet`} component={ItrBalanceSheet} />



          <PrivateRoute path={`${BASE_PATH}clubbing`} component={ClubbingModule} />

          <PrivateRoute path={`${BASE_PATH}exempt-income`} component={ExemptIncome} />

          <PrivateRoute path={`${BASE_PATH}deduction`} component={Deduction} exact />
          <PrivateRoute path={`${BASE_PATH}deduction/schedule-10aa`} component={Shedule10AARA} />

          <PrivateRoute path={`${BASE_PATH}schedule-pti`} component={SchedulePti} />

          <PrivateRoute path={`${BASE_PATH}mat-matc`} component={ScheduleMAT} />
          <PrivateRoute path={`${BASE_PATH}amt-amtc`} component={ScheduleAMT} />

          <PrivateRoute path={`${BASE_PATH}schedule-al`} component={ScheduleAL} />
          <PrivateRoute path={`${BASE_PATH}schedule-fa`} component={ScheduleFA} />

          <PrivateRoute path={`${BASE_PATH}losses`} component={Losses} />

          <PrivateRoute path={`${BASE_PATH}tax-details`} component={TaxDetails} />

          <PrivateRoute path={`${BASE_PATH}computation`} component={Computation} />

          <PrivateRoute path={`${BASE_PATH}efiling`} component={Efiling} />
          <PrivateRoute path={`${BASE_PATH}efiling-review`} component={EfilingReview} />


          <PrivateRoute path={`${BASE_PATH}share-holder-details`} component={ShareHolderDetails} />

          <PrivateRoute path={`${BASE_PATH}residential-status`} component={ResidentialStatus} />

          <PrivateRoute path={`${BASE_PATH}schedulevda`} component={SchduleVDA} />
          <PrivateRoute path={`${BASE_PATH}shareholding`} component={ShareHolding} />


          <PrivateRoute path={`${BASE_PATH}return-register/ay-wise`} component={ReturnRegisterAyWise} />
          <PrivateRoute path={`${BASE_PATH}return-register/client-wise`} component={ReturnRegisterClientWise} />

          <PrivateRoute path={`${BASE_PATH}efiling-review-audit`} component={EfilingReviewAudit} />
          <PrivateRoute path={`${BASE_PATH}security/mfa`} component={TwoFactorAuthentication} />


          <AuthenticationRoute path={`${BASE_PATH}login`} component={LoginScreen} />
          <AuthenticationRoute path={`${BASE_PATH}signup`} component={SignUpScreen} />
          <AuthenticationRoute path={`${BASE_PATH}forgetpass`} component={ForgetPass} />
          <AuthenticationRoute path={`${BASE_PATH}resetpass`} component={ResetPass} />
          <Route path={`${BASE_PATH}maintenance`}>
            {!IS_MAITENANCE_MODE_ENABLED ? <Redirect to={BASE_PATH} /> : <Maintenance />}
          </Route>
          <Route path={BASE_PATH}>
            {BASE_PATH ? <Redirect to={`${BASE_PATH}client/recent`} /> : <NotFound />}
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </main>
  );
}

export default App;
