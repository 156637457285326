export const API_BASE_URL = "https://appdev552.expressitr.com";
export const SITE_BASE_PATH = "/";
export const IN_MAINTENANCE_MODE = false;
export const MAINTENANCE_MESSAGE = "We are doing important maintenance work on the website and will be back shortly.<br /> We apologise for any inconvenience caused. If you need to get in teuch with us, Please use one of the options belows."

// Call Me Button to be Visible or not
export const CFG_CALL_ME_ENABLED = true;

// Max upload file size in MB
export const CFG_MAX_IMPORT_FILE_SIZE = 10;

// For Connector
export const CFG_CONNECTOR_RELEASED_VERSIONS = {cv: 15, sv: 15};

export const RAZORPAY_KEY = 'rzp_test_ZestYvGC5Gz2nv';

// PayU Payment Gateway related
export const CFG_PAYU_CHECKOUT_SCRIPT_SRC = "https://jssdk-uat.payu.in/bolt/bolt.min.js";

export const CFG_DEMO_CALL_ME_CARD_ENABLED = true;

// Maximum house property count
export const CFG_CNT_MAX_HOUSE_PROPERTY = 10;

// Maximum salary count
export const CFG_CNT_MAX_SALARY_EMPLOYER = 4;

// Maximum business count
export const CFG_CNT_MAX_BUSINESS = 3;

export const CFG_PASSWORD_ENCRYPTION_KEY = 'D)TW:83yDo-|QR~z+jzd_u-50!?1ASr6';
export const CFG_PASSWORD_ENCRYPTION_IV = '1234567812345678';

export const CFG_SHOW_MANUAL_IMPORT_JSON = true;

export const SITE_NAME = 'Express ITR';
export const SITE_LOGO = "EXITR";

/* Connector local ports */
export const CFG_CONNECTOR_LOCAL_PORTS = [55211, 25511, 23490, 12557, 32557];

/* Modes to download AIS and 26AS PDF Files on Dashboard : 
  Mode 1 - Download PDFs from Express Connector
  Mode 2 - Download PDFs directly from Server
*/
export const CFG_PDF_AIS_DOWNLOAD_MODE = 1;

export const CFG_PDF_26AS_DOWNLOAD_MODE = 1;

