const {
  API_BASE_URL,
  SITE_BASE_PATH,
  IN_MAINTENANCE_MODE = false,
  MAINTENANCE_MESSAGE = "",
  RAZORPAY_KEY = "",
  CFG_PAYU_CHECKOUT_SCRIPT_SRC = "",

  CFG_CALL_ME_ENABLED = true,
  CFG_DEMO_CALL_ME_CARD_ENABLED = true,

  CFG_CONNECTOR_VERSION_URL = "https://uti.kdkupdate.com/express-connector-2/update.json",
  CFG_CONNECTOR_FAQ_LINK = "",

  CFG_MAX_IMPORT_FILE_SIZE = 10, // Size in MB
  CFG_CNT_MAX_HOUSE_PROPERTY,
  CFG_CNT_MAX_SALARY_EMPLOYER,
  CFG_CNT_MAX_BUSINESS,

  CFG_BULK_CLIENT_RESYNC_LOCAL_STORAGE_KEY = "bulk-client-resync-",
  CFG_BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY = "bulk-client-resync-return-page-",
  
  CFG_PASSWORD_ENCRYPTION_KEY = '',
  CFG_PASSWORD_ENCRYPTION_IV = '',

  CFG_SHOW_MANUAL_IMPORT_JSON = false,
  SITE_LOGO,
  CFG_CONNECTOR_LOCAL_PORTS = [],

  CFG_PDF_26AS_DOWNLOAD_MODE = 1

} = require("../config");

export const BASE_URL = API_BASE_URL;
export const BASE_PATH = SITE_BASE_PATH;
export const LOGO = SITE_LOGO;

export const IS_MAITENANCE_MODE_ENABLED = IN_MAINTENANCE_MODE;
export const MAINTENANCE_MESSAGE_TO_SHOW = MAINTENANCE_MESSAGE;

// Bulk Client Resync Local Storage Key Prefix
export const BULK_CLIENT_RESYNC_LOCAL_STORAGE_KEY = CFG_BULK_CLIENT_RESYNC_LOCAL_STORAGE_KEY;
export const BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY = CFG_BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY;
export const ADD_CLIENT_AUTO_SCAN = 'add-client-auto-scan-';

// CONNECTOR Related
export const CONNECTOR_LOCAL_PORTS = CFG_CONNECTOR_LOCAL_PORTS;
export const HEADER_CONNECTOR_DIGEST_KEY = 'k-data-dt';
export const CONNECTOR_VERSION_URL = CFG_CONNECTOR_VERSION_URL;
export const CONNECTOR_FAQ_LINK = CFG_CONNECTOR_FAQ_LINK;

export const RAZORPAY_PAYMENT_KEY = RAZORPAY_KEY;
export const PAYU_CHECKOUT_SCRIPT_SRC = CFG_PAYU_CHECKOUT_SCRIPT_SRC;

export const CALL_ME_ENABLED = CFG_CALL_ME_ENABLED;
export const DEMO_CALL_ME_CARD_ENABLED = CFG_DEMO_CALL_ME_CARD_ENABLED;
export const CLIENT_RECORD_IN_ONE_PAGE = 17;

export const initialHeaderData = {
  plan_valid: null,
  plan_valid_until: '',
  company_name: '',
  email: '',
  full_name: '',
  mobile_number: '',
  organization_name: '',
  organization_type: '',
  user_role: '',
  return_type: ''
};

export const YES_NO_LIST = [{ value: true, label: "Yes" }, { value: false, label: "No" }];

export const clientstatusList = [
  { label: 'Individual', value: 1 },
  { label: 'Hindu Undivided Family', value: 2 },
  { label: 'Firm', value: 3 },
  { label: 'Company', value: 4 },
  { label: 'Association of Persons', value: 5 },
  { label: 'Body of Individuals', value: 6 },
  { label: 'Local Authority', value: 7 },
  { label: 'Artificial Juridical Person', value: 8 },
  { label: 'Trust', value: 9 },
  { label: 'Government Agency', value: 10 },
  { label: 'Partnership Firm', value: 11 },
  { label: 'Limited Liability Partnership', value: 12 },
  { label: 'Private Company (Domestic)', value: 13 },
  { label: 'Private Company (Non-Domestic)', value: 14 },
  { label: 'Public Company (Domestic)', value: 15 },
  { label: 'Public Company (Non-Domestic)', value: 16 },
  { label: 'Society Registered under Societies Registration Act-1860 or any law corresponding to that Act', value: 17 },
  { label: 'Other Cooperative Society', value: 18 },
  { label: 'Cooperative Bank - Primary Agricultural Credit Society', value: 19 },
  { label: 'Cooperative Bank - Primary Co-operative Agricultural and Rural Development bank', value: 20 },
  { label: 'Cooperative Bank other than Primary Agricultural Credit Society or a Primary Co-operative Agricultural and Rural Development bank', value: 21 },
  { label: 'Estate of the deceased', value: 22 },
  { label: 'Estate of the insolvent', value: 23 },
  { label: 'Business Trust', value: 24 },
  { label: 'Investment Trust', value: 25 },
  { label: 'Public Charitable Trust', value: 26 },
  { label: 'Trust other than trust eligible to file return in ITR-7', value: 27 },
  { label: 'Other AJP', value: 28 },
  { label: 'Any other AOP/BOI', value: 29 }
];

export const clientstatusListForRevamped = [
  { label: 'Individual', value: 1 },
  { label: 'Hindu Undivided Family', value: 2 },
  { label: 'Partnership Firm', value: 11 },
  { label: 'Limited Liability Partnership', value: 12 },
  { label: 'Private Company (Domestic)', value: 13 },
  { label: 'Private Company (Foreign)', value: 14 },
  { label: 'Public Company (Domestic)', value: 15 },
  { label: 'Public Company (Foreign)', value: 16 },
  { label: 'BOI-Business Trust', value: 624 },
  { label: 'BOI-Investment Trust', value: 625 },
  { label: 'BOI-Trust other than trust eligible to file return in ITR-7', value: 627 },
  { label: 'BOI-Any other AOP/BOI', value: 629 },
  { label: 'AJP-Estate of the deceased', value: 22 },
  { label: 'AJP-Estate of the insolvent', value: 23 },
  { label: 'Other AJP', value: 28 },
  { label: 'AOP-Business Trust', value: 524 },
  { label: 'AOP-Investment Trust', value: 525 },
  { label: 'AOP-Trust other than trust eligible to file return in ITR-7', value: 527 },
  { label: 'AOP-Any other AOP/BOI', value: 529 },
  { label: 'Cooperative Bank other than Primary Agricultural Credit Society or a Primary Co-operative Agricultural and Rural Development bank', value: 21 },
  { label: 'Cooperative Bank - Primary Co-operative Agricultural and Rural Development bank', value: 20 },
  { label: 'Other Cooperative Society', value: 18 },
  { label: 'Society Registered under Societies Registration Act-1860 or any law corresponding to that Act', value: 17 },
  { label: 'Cooperative Bank - Primary Agricultural Credit Society', value: 19 },
  { label: 'Local Authority', value: 7 },
  { label: 'Trust-Society Registered under Societies Registration Act-1860 or any law corresponding to that Act', value: 917 },
  { label: 'Public Charitable Trust', value: 26 },
  { label: 'Trust-Any other AOP/BOI', value: 929 },
  { label: 'Trust-Company', value: 904 },
  { label: 'Government Agency', value: 10 },
];

export const monthListForMulti = [
  { label: "Apr", value: 'APR' },
  { label: "May", value: 'MAY' },
  { label: "Jun", value: 'JUN' },
  { label: "Jul", value: 'JUL' },
  { label: "Aug", value: 'AUG' },
  { label: "Sep", value: 'SEP' },
  { label: "Oct", value: 'OCT' },
  { label: "Nov", value: 'NOV' },
  { label: "Dec", value: 'DEC' },
  { label: "Jan", value: 'JAN' },
  { label: "Feb", value: 'FEB' },
  { label: "Mar", value: 'MAR' }
];


export const PLACE_OF_SUPPLY_LIST = [
  { label: "01-Jammu and Kashmir", value: "01" },
  { label: "02-Himachal Pradesh", value: "02" },
  { label: "03-Punjab", value: "03" },
  { label: "04-Chandigarh", value: "04" },
  { label: "05-Uttarakhand", value: "05" },
  { label: "06-Haryana", value: "06" },
  { label: "07-Delhi", value: "07" },
  { label: "08-Rajasthan", value: "08" },
  { label: "09-Uttar Pradesh", value: "09" },
  { label: "10-Bihar", value: "10" },
  { label: "11-Sikkim", value: "11" },
  { label: "12-Arunachal Pradesh", value: "12" },
  { label: "13-Nagaland", value: "13" },
  { label: "14-Manipur", value: "14" },
  { label: "15-Mizoram", value: "15" },
  { label: "16-Tripura", value: "16" },
  { label: "17-Meghalaya", value: "17" },
  { label: "18-Assam", value: "18" },
  { label: "19-West Bengal", value: "19" },
  { label: "20-Jharkhand", value: "20" },
  { label: "21-Odisha", value: "21" },
  { label: "22-Chattisgarh", value: "22" },
  { label: "23-Madhya Pradesh", value: "23" },
  { label: "24-Gujarat", value: "24" },
  { label: "26-Dadra and Nagar Haveli and Daman and Diu", value: "26" },
  { label: "27-Maharashtra", value: "27" },
  { label: "28-Andhra Pradesh", value: "28" },
  { label: "29-Karnataka", value: "29" },
  { label: "30-Goa", value: "30" },
  { label: "31-Lakshadweep", value: "31" },
  { label: "32-Kerala", value: "32" },
  { label: "33-Tamil Nadu", value: "33" },
  { label: "34-Puducherry", value: "34" },
  { label: "35-Andaman AND Nicobar Islands", value: "35" },
  { label: "36-Telangana", value: "36" },
  { label: "37-Andhra Pradesh", value: "37" },
  { label: "38-Ladakh", value: "38" },
  { label: "97-Other Territory", value: "97" }
];


export const LISTED_UNLISTED = [{ value: 'L', label: 'Listed' }, { value: 'UL', label: 'Unlisted' }]
export const NATURE_OF_ASSETS = [{ value: 'USOC', label: 'Unquoted Shares of a company' }, { value: 'DOMF', label: 'Debt Oriented Mutual Fund' },
{ value: 'FEA', label: 'Foreign Exchange Assets' }, { value: 'OA', label: 'Other Assets' }]
export const TYPE_OF_CG = [{ value: 'ST', label: 'Short Term' }, { value: 'LT', label: 'Long Term' }]
export const TYPE_OF_SECTION = [{ value: '54', label: '54' }, { value: '54B', label: '54B' }, { value: '54D', label: '54D' },
{ value: '54F', label: '54F' }, { value: '54G', label: '54G' }, { value: '54GA', label: '54GA' },
{ value: '54GB', label: '54GB' }]


export const MONTH_NUMBER_TO_TEXT = {
  "1": "JAN",
  "2": "FEB",
  "3": "MAR",
  "4": "APR",
  "5": "MAY",
  "6": "JUN",
  "7": "JUL",
  "8": "AUG",
  "9": "SEP",
  "10": "OCT",
  "11": "NOV",
  "12": "DEC"
};

export const MONTH_NUMBER_TO_FULL_TEXT = {
  "1": "January",
  "2": "February",
  "3": "March",
  "4": "April",
  "5": "May",
  "6": "June",
  "7": "July",
  "8": "August",
  "9": "September",
  "10": "October",
  "11": "November",
  "12": "December"
};

export const CONNECTOR_ERRORS_MAPPING = {
  'default': 'Something went wrong! Please try again after some time.',
  'itefil.unknown_error': 'Something went wrong. Please try again.',
  'itefil.invalid_pan': 'Invalid PAN!',
  'itefil.invalid_password': 'Invalid Password!',
}

export const CONNECTOR_USERNAME_PASSWORD_ERRORS = {
  'itefil.invalid_pan': 'This PAN does not exist. Please register this PAN on ITD',
  'itefil.invalid_password': 'Invalid Password!',
}

export const MAX_IMPORT_FILE_SIZE = CFG_MAX_IMPORT_FILE_SIZE;
export const ADDON_GST_TAX_RATE = 18.0;

// General Headers
export const HEADER_FILE_NAME = 'k-file-name';

export const SHOW_KEYS = {
  listed_unlisted: {
    L: 'Listed',
    UL: 'Unlisted'
  },
  nature_of_assets: {
    'USOC': 'Unquoted Shares of a company',
    'DOMF': 'Debt Oriented Mutual Fund',
    'FEA': 'Foreign Exchange Assets', 'OA': 'Other Assets'
  },
  type_of_cg: {
    'ST': 'Short Term',
    'LT': 'Long Term'
  },
  yes_no_list: {
    true: "Yes",
    false: "No"
  }

}

export const AGRICULTURAL_2V_MIN_AMT = 500000;

export const INC_EXC_NOT_CHARGE_TAX_STATUSLIST = [5, 6, 8, 9, 22, 24, 25, 27, 26, 29];

export const OWNED_LEASED = [
  { label: "Owned assets", value: "OWNED" },
  { label: "Lease", value: "LEASE" },
  { label: "Operating Lease", value: "OPERATING_LEASE" },
];

export const SHIFT = [
  { label: "Single", value: "SINGLE" },
  { label: "Double", value: "DOUBLE" },
  { label: "Triple", value: "TRIPLE" },
];

export const MAX_PROPERTY_COUNT = CFG_CNT_MAX_HOUSE_PROPERTY;
export const MAX_SALARY_COUNT = CFG_CNT_MAX_SALARY_EMPLOYER;
export const MAX_BUSINESS_COUNT = CFG_CNT_MAX_BUSINESS;

export const TOUR_STEPS = {
  'dashbaord': [
    {
      selector: ".exprit_infoiconpan span",
      content: "View important Insights about your PAN by clicking here",
      position: "right"
    },
    {
      selector: ".it-portal-login",
      content: "Click on the IT Portal logo to access the Government Portal ",
      position: "right"
    },
    {
      selector: ".callmebgclr",
      content: "Click here to get immediate assistance.",
      position: "right"
    },
  ],
  'client_master': [
    {
      selector: ".callmebgclr",
      content: "Click here to get immediate assistance.",
      position: "right"
    },
    {
      selector: ".exp_itc_plus_fs img",
      content: "Click here to Add a new Client",
      position: "right"
    },
    {
      selector: ".ca-master-btn",
      content: "Click here to manage CA Master",
      position: "right"
    },
  ]
}

export const YEAR_2024 = 2024;

export const PASSWORD_ENCRYPTION_KEY = CFG_PASSWORD_ENCRYPTION_KEY;
export const PASSWORD_ENCRYPTION_IV = CFG_PASSWORD_ENCRYPTION_IV;

export const ACCOUNT_LOCKED_MESSAGE = 'Your account is locked due to incorrect password attempts. Please reset your password.';

export const SHOW_MANUAL_IMPORT_JSON = CFG_SHOW_MANUAL_IMPORT_JSON;

export const PDF_26AS_DOWNLOAD_MODE = CFG_PDF_26AS_DOWNLOAD_MODE;

